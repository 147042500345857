import firebase from 'firebase';
import { TDR } from 'tdr-common';

export const updateRestaurantSettings = async (
	firestore: firebase.firestore.Firestore,
	restaurantId: string,
	widgetSettings: TDR.Restaurant['settings']
) => {
	const docRef = firestore.collection('Restaurants').doc(restaurantId);

	const doc = await docRef.get();
	const currentSettings = doc.data()?.settings || {};

	const mergedSettings = {
		...widgetSettings,
		alternativeBookingOption: currentSettings.alternativeBookingOption
	};

	await docRef.update({ settings: mergedSettings });
};

export const updateRestaurantLinking = async (
	firestore: firebase.firestore.Firestore,
	restaurantId: string,
	linking: TDR.Restaurant['settings']['alternativeBookingOption']
) => {
	const docRef = firestore.collection('Restaurants').doc(restaurantId);
	await docRef.update({ 'settings.alternativeBookingOption': linking });
};