import React, { useContext, useState } from 'react';
import {
	Box,
	Typography,
	Paper,
	Grid,
	TextField,
	MenuItem,
	Button,
	Snackbar,
	Container,
	makeStyles
} from '@material-ui/core';
import { RestaurantContext } from '../context/RestaurantContext';
import { updateRestaurantLinking } from '../api/updateWidgetSettings';
import { FirebaseContext } from '../context/FirebaseContext';
import { red } from '@material-ui/core/colors';

interface FormData {
	restaurantId: string;
	selfLabel: string;
}

const useStyles = makeStyles((theme) => ({
	unlinkButton: {
		borderColor: red[500],
		color: red[500],
		'&:hover': {
			borderColor: red[700],
			color: red[700],
			backgroundColor: 'rgba(211, 47, 47, 0.04)'
		},
		'&.Mui-disabled': {
			borderColor: theme.palette.action.disabled,
			color: theme.palette.action.disabled
		}
	}
}));

const AdminScanLinking: React.FC = () => {
	const classes = useStyles();
	const { restaurants } = useContext(RestaurantContext);
	const { firestore } = useContext(FirebaseContext);
	const [leftForm, setLeftForm] = useState<FormData>({ restaurantId: '', selfLabel: '' });
	const [rightForm, setRightForm] = useState<FormData>({ restaurantId: '', selfLabel: '' });
	const [showSuccess, setShowSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');

	const ScanLinkingForm = ({
		data,
		onChange
	}: {
		data: FormData;
		onChange: (data: FormData) => void;
	}) => {
		const [tempLabel, setTempLabel] = useState(data.selfLabel);

		return (
			<Box display="flex" flexDirection="column" >
				<TextField
					select
					fullWidth
					label="Restaurant"
					variant="outlined"
					value={data.restaurantId}
					onChange={(e) => {
						onChange({ ...data, restaurantId: e.target.value });
						setTempLabel(data.selfLabel);
					}}
					style={{ marginBottom: '16px' }}
				>
					{restaurants.map((restaurant) => (
						<MenuItem key={restaurant.id} value={restaurant.id}>
							{restaurant.name}
						</MenuItem>
					))}
				</TextField>
				<TextField
					fullWidth
					label="Label"
					variant="outlined"
					placeholder="Enter label"
					value={tempLabel}
					onChange={(e) => setTempLabel(e.target.value)}
					onBlur={() => {
						if (tempLabel !== data.selfLabel) {
							onChange({ ...data, selfLabel: tempLabel });
						}
					}}
					style={{ marginBottom: '16px' }}
				/>
			</Box>
		);
	};

	const handleSave = async () => {
		const leftRestaurant = restaurants.find(r => r.id === leftForm.restaurantId);
		const rightRestaurant = restaurants.find(r => r.id === rightForm.restaurantId);

		if (!leftRestaurant || !rightRestaurant) {
			return;
		}

		// Update left restaurant
		const leftUpdate = {
			label: rightForm.selfLabel,
			href: `${rightRestaurant.slug}/explore`,
			selfLabel: leftForm.selfLabel,
			selfPosition: 'left' as const,
			landingImageUrl: rightRestaurant.backgroundImagePath || ''
		};

		// Update right restaurant
		const rightUpdate = {
			label: leftForm.selfLabel,
			href: `${leftRestaurant.slug}/explore`,
			selfLabel: rightForm.selfLabel,
			selfPosition: 'right' as const,
			landingImageUrl: leftRestaurant.backgroundImagePath || ''
		};

		try {
			await Promise.all([
				updateRestaurantLinking(firestore, leftRestaurant.id, leftUpdate),
				updateRestaurantLinking(firestore, rightRestaurant.id, rightUpdate)
			]);
			setSuccessMessage('Scan linking updated successfully');
			setShowSuccess(true);
		}
		catch (error) {
			console.error('Error updating restaurants:', error);
		}
	};

	const handleUnlink = async () => {
		const leftRestaurant = restaurants.find(r => r.id === leftForm.restaurantId);
		const rightRestaurant = restaurants.find(r => r.id === rightForm.restaurantId);

		if (!leftRestaurant || !rightRestaurant) {
			return;
		}

		try {
			await Promise.all([
				updateRestaurantLinking(firestore, leftRestaurant.id, null),
				updateRestaurantLinking(firestore, rightRestaurant.id, null)
			]);
			setSuccessMessage('Restaurants unlinked successfully');
			setShowSuccess(true);
			// Clear the labels
			setLeftForm({ restaurantId: leftRestaurant.id, selfLabel: '' });
			setRightForm({ restaurantId: rightRestaurant.id, selfLabel: '' });
		}
		catch (error) {
			console.error('Error unlinking restaurants:', error);
		}
	};

	const bothFormsSelected = leftForm.restaurantId && rightForm.restaurantId;
	const bothFormsComplete = bothFormsSelected && leftForm.selfLabel && rightForm.selfLabel;

	return (
		<Container maxWidth="md" style={{ marginTop: '24px' }}>
			<Paper>
				<Box p={3}>
					<Typography variant="h5" gutterBottom align="center">
						Scan Linking
					</Typography>
					<Typography variant="body1" gutterBottom>
						Link restaurants - left form for left side on landing page, right form for right side.
					</Typography>

					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>

							<ScanLinkingForm
								data={leftForm}
								onChange={setLeftForm}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<ScanLinkingForm
								data={rightForm}
								onChange={setRightForm}
							/>
						</Grid>
					</Grid>

					<Box mt={3} display="flex" justifyContent="flex-end">
						<Button
							variant="outlined"
							className={classes.unlinkButton}
							onClick={handleUnlink}
							disabled={!bothFormsSelected}
							style={{ marginRight: '8px' }}
						>
							Unlink
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={handleSave}
							disabled={!bothFormsComplete}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Paper>
			<Snackbar
				open={showSuccess}
				autoHideDuration={6000}
				onClose={() => setShowSuccess(false)}
				message={successMessage}
			/>
		</Container>
	);
};

export default AdminScanLinking;