import firebase from 'firebase/app';
import 'firebase/firestore';
import { DateTime } from 'luxon';
import { TDR } from 'tdr-common';
import { v4 as uuidv4 } from 'uuid';
import luxonToFirebaseTimestamp from '../helpers/luxonToFirebaseTimestamp';

export const resendCommunication = async (commId: string): Promise<void> => {
	try {
		const firestore = firebase.firestore();

		// Get the original communication
		const commDoc = await firestore.collection('Communications').doc(commId).get();
		if (!commDoc.exists) {
			throw new Error('Communication not found');
		}

		const originalComm = commDoc.data() as TDR.Reservation.Communication;

		// Create a new communication document with status 'created'
		await firestore.collection('Communications').add({
			...originalComm,
			id: uuidv4(),
			status: 'created',
			createdAt: luxonToFirebaseTimestamp(DateTime.local())
		});
	}
	catch (error) {
		console.error('Error resending communication:', error);
		throw error;
	}
};