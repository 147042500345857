import { Button, Card, IconButton } from '@material-ui/core';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';
import React, { useState } from 'react';
import { TDR } from 'tdr-common';
import { timeStr } from '../helpers/timeStr';
import Modal from './Modal';
import SendIcon from '@material-ui/icons/Send';
import { resendCommunication } from '../api/communications';

const Communication = ({
	comm,
	firestore,
	restaurant
}: {
	firestore: any;
	comm: TDR.Reservation.Communication;
	restaurant: TDR.Restaurant;
}) => {
	const fromCustomer = comm.type === TDR.Reservation.CommunicationType.MiscFromCustomer;
	const fromAdmin = comm.type === TDR.Reservation.CommunicationType.MiscToCustomer;
	const fromSystem = !fromCustomer && !fromAdmin;

	const deliveryFailed = TDR.Reservation.messageStatusFailed(comm.status);
	const unacknowledgedFailure = deliveryFailed && !comm.failureAcknowledged;

	const [modalOpen, setModalOpen] = useState(false);
	const [isResending, setIsResending] = useState(false);

	const { Email } = TDR.Reservation.CommunicationChannel;

	const handleResend = async () => {
		try {
			setIsResending(true);
			await resendCommunication(comm.id);
		}
		catch (error) {
			console.error('Failed to resend:', error);
			// You might want to add a toast or alert here
		}
		finally {
			setIsResending(false);
		}
	};

	return (
		<Card
			className={[
				'message',
				TDR.Reservation.messageStatusFailed(comm.status) ? 'failed' : '',
				fromCustomer ? 'from-customer' : '',
				fromAdmin ? 'from-admin' : '',
				fromSystem ? 'from-system' : '',
				unacknowledgedFailure ? 'failed' : ''
			].join(' ')}
		>
			<div>
				<span className='date'>
					{timeStr(comm?.timestamp, restaurant.timezone)}
				</span>
				{fromSystem ? <span className='type'>{comm.type}</span> : null}
			</div>

			<div className='body'>
				{comm.channel === Email ? (
					<Button onClick={() => setModalOpen(!modalOpen)}>Show Email</Button>
				) : (
					comm.body
				)}
			</div>

			{comm.channel === Email && (
				<Modal
					open={modalOpen}
					onClose={() => setModalOpen(false)}
					title={comm.subject}
					fullScreen={true}
				>
					<iframe srcDoc={comm.body} width='100%' height='100%'></iframe>
				</Modal>
			)}

			{deliveryFailed ? (
				<div className='failed-label'>DELIVERY FAILED</div>
			) : null}

			<div style={{ width: '100%' }}>
				{unacknowledgedFailure ? (
					<IconButton
						style={{ float: 'left' }}
						onClick={() =>
							firestore
								.collection('Communications')
								.doc(comm.id)
								.update({ failureAcknowledged: true })
						}
					>
						<AlarmOffIcon />
					</IconButton>
				) : null}

				{comm.channel === Email && (
					<IconButton
						style={{ float: 'right', padding: '4px' }}
						onClick={handleResend}
						disabled={isResending}
						size="small"
					>
						{isResending ? (
							<span style={{ fontSize: '0.75rem' }}>resending...</span>
						) : (
							<>
								<span style={{ fontSize: '0.75rem', marginRight: '4px' }}>resend</span>
								<SendIcon fontSize="small" />
							</>
						)}
					</IconButton>
				)}
			</div>
		</Card>
	);
};

export default Communication;
